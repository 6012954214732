import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Customer, PassangerTypes } from "../../customers/models/customer";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-passanger-list",
  templateUrl: "./passangers.list.component.html",
  animations: [toggleAnimation],
})
export class PassangersListComponent implements OnInit {
  @Input() withReservation = false;
  constructor(public translate: TranslateService) {}

  public passengers: Customer[] = [];
  public editPassanger!: Customer | undefined;
  public validInputs = false;
  ngOnInit(): void {
    this.setMainCustomer();
  }
  passengerTypes: PassangerTypesContainer[] = [
    { type: PassangerTypes.adults, label: this.translate.instant("ticket_checkout.adults"), imageUrl: "/assets/images/man.svg" } as PassangerTypesContainer,
    { type: PassangerTypes.children, label: this.translate.instant("ticket_checkout.children"), imageUrl: "/assets/images/boy.svg" } as PassangerTypesContainer,
    { type: PassangerTypes.infants, label: this.translate.instant("ticket_checkout.infants"), imageUrl: "/assets/images/baby.svg" } as PassangerTypesContainer,
  ];

  passengerTypesMap = {
    [PassangerTypes.adults]: { type: PassangerTypes.adults, label: "Adults", imageUrl: "/assets/images/man.svg" },
    [PassangerTypes.infants]: { type: PassangerTypes.infants, label: "Infants", imageUrl: "/assets/images/baby.svg" },
    [PassangerTypes.children]: { type: PassangerTypes.children, label: "Children", imageUrl: "/assets/images/boy.svg" },
  };

  setMainCustomer(customer?: Customer): void {
    if (customer) {
      this.passengers.push(customer);
    } else {
      this.passengers.push({
        lifeStage: PassangerTypes.adults,
        guid: crypto.randomUUID(),
        imageUrl: "/assets/images/man.svg",
        isMainCustomer: true,
        mainCustomer: true,
      } as Customer);
    }
    // this.toEditPassanger(this.passengers[0]);
  }

  getPassengerCount(type: PassangerTypes): number {
    return this.passengers.filter((passenger) => passenger.lifeStage === type).length;
  }

  addOrUpdatePassenger(passenger: Customer): void {
    const index = this.passengers.findIndex((p) => p.id === passenger.id);
    if (index !== -1) {
      this.passengers[index] = passenger;
      console.log("Updated passenger:", passenger);
    } else {
      this.passengers.push(passenger);
      console.log("Added new passenger:", passenger);
    }
  }

  changePassengerCount(event: number, type: PassangerTypesContainer) {
    const currentCount = this.getPassengerCount(type.type);
    const diff = event - currentCount;

    if (diff > 0) {
      this.passengers.push({ lifeStage: type.type, guid: crypto.randomUUID(), imageUrl: type.imageUrl, isMainCustomer: false } as Customer);
    } else if (diff < 0) {
      const passengersToRemove = this.passengers.filter((passenger) => passenger.lifeStage === type.type);
      const elementIndex = this.passengers.indexOf(passengersToRemove[passengersToRemove.length - 1]);
      this.passengers.splice(elementIndex, 1);
    }
  }

  handlePassanger(passanger: Customer) {
    const updatedCustomer = { ...this.editPassanger, ...passanger };
    const index = this.passengers.findIndex((e) => e.guid === updatedCustomer.guid);

    if (index !== -1) {
      this.passengers[index] = updatedCustomer;
    } else {
      this.passengers.push(updatedCustomer);
    }

    this.editPassanger = undefined;
  }

  removePassanger(guid: string) {
    this.editPassanger = undefined;
    this.passengers = this.passengers.filter((e) => e.guid != guid);
  }

  toEditPassanger(passanger: Customer) {
    if (this.editPassanger) {
      this.editPassanger = undefined;
    }
    setTimeout(() => {
      this.editPassanger = passanger;
    });
  }

  push(passangers: Customer[]) {
    this.passengers = passangers.map((p) => {
      if (!p.lifeStage || p.mainCustomer) {
        p.imageUrl = this.passengerTypesMap[PassangerTypes.adults].imageUrl;
      } else {
        p.imageUrl = this.passengerTypesMap[p.lifeStage].imageUrl;
      }
      return { ...p, guid: crypto.randomUUID() };
    });
  }

  getPassangers(): Customer[] {
    return this.passengers;
  }

  checkValidity() {
    if (this.editPassanger || !this.isPassengerSaved()) {
      showMessage(PopupType.Danger, this.translate.instant("passanger_needs_to_be_saved"));
      return false;
    }
    return true;
  }

  isPassengerSaved() {
    const notSavePassenger = this.passengers.find((p) => p.name === undefined || p.surname === undefined || p.email === undefined || p.phoneNumber === undefined);
    if (notSavePassenger) {
      return false;
    }
    return true;
  }
}

interface PassangerTypesContainer {
  type: PassangerTypes;
  label: string;
  imageUrl: string;
}
