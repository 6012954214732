import * as moment from "moment";
import { Subscription } from "rxjs";
import { Sale } from "../../sales/models/sale";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Customer, PassangerTypes } from "../../customers/models/customer";
import { TransfersService } from "../services/transfers.service.service";
import { SaleCreateComponent } from "../../sales/sale.create/sale.create.component";
import { Transfer, TransferPassanger, TransferPlanEdit } from "../models/transfer";
import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { PassangersListComponent } from "../../passangers/passangers.list/passangers.list.component";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";

@Component({
  selector: "app-transfer-plan-edit",
  templateUrl: "./transfer-plan-edit.component.html",
})
export class TransferPlanEditComponent implements OnInit, OnDestroy {
  @Input() passangerId!: number;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("sale") sales!: SaleCreateComponent;
  activeTab = 1;
  passengers!: Customer[];
  public sale!: Sale;
  id!: number;
  transfer!: Transfer;
  customer!: Customer;
  public mainCustomer!: Customer;
  public planId!: number;
  public customerId!: number;
  transferId!: number;
  private routeSubscription!: Subscription;

  constructor(private transferService: TransfersService, private translate: TranslateService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.fetchEntities();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  fetchEntities() {
    const fetchSubscription = this.transferService.getTransferPlan(this.id).subscribe((response) => {
      const { customer, plan, transferPassangers, transferId } = response;

      this.transferId = transferId;
      this.sale = plan;
      this.planId = plan.id;
      this.sales.initForm(plan);

      const customerList = transferPassangers.filter((p) => p.customer.id !== customer.id).map((p) => ({ ...p.customer, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer }));
      this.passangerList.push(customerList);

      this.mainCustomer = {
        ...customer,
        lifeStage: PassangerTypes.adults,
        guid: crypto.randomUUID(),
        imageUrl: "/assets/images/man.svg",
        mainCustomer: true,
        isMainCustomer: transferPassangers.find((p) => p.customerId === customer.id)?.isMainCustomer,
      } as Customer;
      if (this.passangerList) {
        this.customerId = customer.id;
        this.passangerList.setMainCustomer(this.mainCustomer);
      }
      fetchSubscription.unsubscribe();
    });
  }

  proceedToNextStage(nextStep: number | undefined = undefined) {
    if (this.activeTab === 1) {
      if (!this.passangerList.getPassangers().find((e: Customer) => e.mainCustomer)) return;
      this.customer = this.passangerList.getPassangers().find((e: Customer) => e.mainCustomer) as Customer;
      if (this.customer.name === undefined || this.customer.surname === undefined) {
        showMessage(PopupType.Danger, this.translate.instant("popup.main_customer_is_required"));
        return;
      }
    }

    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  updateTransferPassangers() {
    if (this.sales.checkIfValid() && this.passangerList.checkValidity()) {
      this.passengers = this.passangerList.getPassangers();
      this.sale = this.sales.getSale();
      const updateSub = this.transferService.updateTransferPassanger(this.id, this.updateReservationObject()).subscribe({
        next: () => {
          showMessage(PopupType.Success, this.translate.instant("transfers_info.updated_passengers_successfully"));
          updateSub.unsubscribe();
          this.router.navigate(["/dashboard/transfers" + "/" + this.transferId]);
        },
        error: () => {
          updateSub.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("transfers_info.error_while_updating_passengers"));
        },
      });
    }
  }

  updateReservationObject(): TransferPlanEdit {
    return {
      id: this.planId,
      transferId: this.transferId,
      plan: {
        ...this.sales.getSale(),
        dueDate: moment(this.sale.dueDate, "DD.MM.YYYY").toISOString(),
        customerId: this.customerId,
      },
      customerId: this.customerId,
      passangers: this.passangerList.getPassangers().map((p: Customer) => {
        return {
          customerId: p.id,
          lifeStage: p.lifeStage,
          isMainCustomer: p.isMainCustomer,
        } as unknown as TransferPassanger;
      }),
    };
  }
}
