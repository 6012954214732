import * as moment from "moment";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { StyleUtil } from "src/app/shared/utils/style.util";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Travel } from "src/app/domain/travels/models/travel";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { CheckoutService } from "../../services/checkout.service";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Organizer } from "src/app/domain/organizers/models/organizers";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { Customer, PassangerTypes } from "../../../domain/customers/models/customer";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { TravelCreateComponent } from "src/app/domain/travels/travel.create/travel.create.component";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";
import { DestinationsListComponent } from "src/app/domain/destinations/destinations.list/destinations.list.component";
import { TravelDestinationCreateComponent } from "src/app/domain/travels/travel-destination.create/travel-destination.create.component";

@Component({
  templateUrl: "./travel-checkout.edit.component.html",
})
export class TravelCheckoutEditComponent implements OnInit, OnDestroy {
  @ViewChild("mainDestination") mainDestination!: TravelDestinationCreateComponent;
  @ViewChild("destinations") destinations!: DestinationsListComponent;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("travelBase") travelComponent!: TravelCreateComponent;
  @ViewChild("sale") saleComponent!: SaleCreateComponent;
  public allPassangers: Customer[] = [];
  organizer!: Organizer;
  public activeTab = 1;
  note!: string;
  public id!: number;
  public sale!: SaleBase;
  public travel!: Travel;
  public customer!: Customer;
  public isExistsAnnouncement!: boolean;
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  public tabSizeHandler = StyleUtil.getTabWidth;
  private routeSubscribtion!: Subscription;
  private routeDoubleSubscription!: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, public checkoutService: CheckoutService, public translate: TranslateService) {}

  ngOnInit() {
    this.routeSubscribtion = this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.routeDoubleSubscription = this.route.queryParams.subscribe((params) => {
        const activeTab = parseInt(params["activeTab"]);
        this.activeTab = activeTab;
        if (this.router.url.includes("draft/edit") || this.router.url.includes("draft/accept")) {
          this.isExistsAnnouncement = true;
          this.fetchEntities();
        } else {
          this.isExistsAnnouncement = false;
        }
      });
    });
  }

  ngOnDestroy() {
    this.routeSubscribtion.unsubscribe();
    this.routeDoubleSubscription.unsubscribe();
  }

  fetchEntities() {
    const fetchSubscription = this.checkoutService.fetchTravel(this.id).subscribe((response) => {
      fetchSubscription.unsubscribe();
      const { passangers: customerPassagers, customer, plan, travelDestinations, travelPassagers: passangers } = response;

      const customerList = passangers.filter((p) => p.customer && p.customer.id !== customer.id).map((p) => ({ ...p.customer, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer }));
      this.passangerList.push(customerList);

      this.customer = {
        ...customer,
        lifeStage: PassangerTypes.adults,
        guid: crypto.randomUUID(),
        imageUrl: "/assets/images/man.svg",
        mainCustomer: true,
        isMainCustomer: passangers.find((p) => p.customerId === customer.id)?.isMainCustomer,
      } as Customer;

      if (this.passangerList) {
        this.passangerList.setMainCustomer(this.customer);
      }

      this.saleComponent.initForm(DateTimeFormatter.formatPlanDates({ ...plan, customer: customer, customerId: customer.id }));
      this.sale = this.saleComponent.getSale();
      this.note = plan.notes ?? "";

      this.travelComponent.initForm(DateTimeFormatter.formatTravelDates(response));
      this.travel = this.travelComponent.getTravel();

      const mainDestination = travelDestinations.find((t) => !t.isMainDestination) ?? ({} as TravelDestination);
      this.mainDestination.initForm(mainDestination);

      this.destinations.push(travelDestinations.filter((t) => !t.isMainDestination).map((t) => DateTimeFormatter.formatTravelDestinationDates(t)));
    });
  }

  onNotify(data: any) {
    this.mainDestination.setStartAndEnd(data);
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  createReservationObject(): any {
    const { start, end, type, days, nights, organizer, departure } = this.travel;
    const { bruto, neto, avans, dueDate, paymentTypeId } = this.sale;
    const { id } = this.customer;
    const isoDueDate = moment(dueDate, "DD.MM.YYYY").toISOString();

    // Extracting necessary IDs
    const organizerId: number = organizer?.id;
    const departureId: number | undefined = departure ? departure?.id : undefined;

    let passangers = this.passangerList.getPassangers().map((e) => {
      return { customerId: e.id, lifeStage: e.lifeStage, isMainCustomer: e.isMainCustomer };
    });

    const mainCustomer = this.passangerList.getPassangers().find((e) => e.mainCustomer);
    if (mainCustomer?.mainCustomer == false) passangers = passangers.filter((e) => e.customerId !== mainCustomer?.id);

    if (this.sale.withAvans === 0) {
      this.sale.avans = undefined;
    }

    const otherDest = this.destinations.getDestinations() || [];

    const destinations = [...otherDest, { ...this.mainDestination.getTravel(), isMainDestination: true } as TravelDestination];

    const newReservation = {
      start: moment(start, "DD.MM.YYYY").toISOString(),
      end: moment(end, "DD.MM.YYYY").toISOString(),
      days,
      nights,
      typeId: type?.id,
      customerId: id,
      numberOfPassangers: passangers.length,
      plan: { bruto, neto, avans, dueDate: isoDueDate, customerId: id, notes: this.note, paymentTypeId },
      planId: this.sale.id,
      passangers,
      departureId,
      organizerId,
      travelDestinations: destinations.map((p) => {
        const { id, travelId, destination, hotel, start: travelStart, end: travelEnd, service, days, nights, roomDescription } = p;
        return {
          id,
          start: moment(travelStart, "DD.MM.YYYY").toISOString(),
          end: moment(travelEnd, "DD.MM.YYYY").toISOString(),
          days,
          nights,
          roomDescription,
          serviceId: service?.id,
          destinationId: destination?.id,
          hotelId: hotel?.id,
          travelId: travelId,
        };
      }),
    };

    return newReservation;
  }

  updateTravelDraft() {
    this.travel = this.travelComponent.getTravel();

    const travelDraft = this.createReservationObject();

    if (!this.travelComponent.checkIfValid()) {
      showMessage(PopupType.Danger, "Travel hasn't been defined fully"); // TODO: translate
      return;
    }

    if (!this.mainDestination.checkIfValid()) {
      showMessage(PopupType.Danger, "Main destination is missing some info please add them"); // TODO: translate
      return;
    }

    if (!this.passangerList.checkValidity()) {
      showMessage(PopupType.Danger, "Passanger Validation failed"); // TODO: translate
      return;
    }

    if (!this.saleComponent.checkIfValid()) {
      showMessage(PopupType.Danger, "Sale object is not valid"); // TODO: translate
      return;
    }

    const updateSub = this.checkoutService.updateTravelDraft(this.id, { ...travelDraft, ...travelDraft.plan }).subscribe({
      next: () => {
        showMessage(PopupType.Success, this.translate.instant("ticket_edited_successfully"));
        updateSub.unsubscribe();
        this.proceedToNextStage(this.activeTab + 1);
      },
      error: (error) => {
        updateSub.unsubscribe();
        showMessage(PopupType.Danger, error);
      },
    });
  }

  handleVoucherSent() {
    // show payment slip for avans
    this.proceedToNextStage(this.activeTab + 1);
  }

  proceedToNextStage(nextStep: number) {
    if (nextStep == 2) {
      this.customer = this.passangerList.getPassangers().find((e) => e.mainCustomer) as Customer;
      if (this.customer.name === undefined || this.customer.surname === undefined || !this.passangerList.checkValidity()) {
        showMessage(PopupType.Danger, "Main customer is required or please save the passanger"); // TODO: translate
        return;
      }
    }

    if (nextStep === 3) {
      if (!this.isExistsAnnouncement) {
        this.activeTab = 4;
        return;
      }
    }

    if (this.isExistsAnnouncement && nextStep === 3) {
      this.activeTab = 4;
      return;
    }

    if (!this.isExistsAnnouncement && nextStep === 3) {
      this.activeTab = 4;
      return;
    }

    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  returnBack() {
    this.activeTab = this.activeTab - 1;
    if (!this.isExistsAnnouncement && this.activeTab == 3) {
      this.activeTab = 2;
    }
  }
}
