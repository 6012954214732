import * as moment from "moment";
import { Vehicle } from "../models/transfer";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TransfersService } from "../services/transfers.service.service";
import { dateTimeFormatValidator } from "src/app/shared/validators/date.validator";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";

@Component({
  selector: "app-transfer-create",
  templateUrl: "./transfer.create.component.html",
})
export class TransferCreateComponent implements OnInit {
  EntityPicklistType = EntityPicklistType;
  PicklistType = PicklistType;
  id: number | undefined;
  form!: FormGroup;
  vehicles!: Vehicle[];
  selectedVehicle!: Vehicle;
  @ViewChild("nameInput") nameInput!: ElementRef;
  @ViewChildren("formInput") formInputs!: QueryList<ElementRef>;
  @ViewChildren("submitBtn") submitBtn!: ElementRef;
  constructor(public fb: FormBuilder, public route: ActivatedRoute, public translate: TranslateService, private transfersService: TransfersService, public utilService: UtilService, private router: Router) {}
  ngOnInit(): void {
    this.initFormIntern();
    const getTransferPicklist = this.transfersService.getTransfersPicklist().subscribe((res) => {
      getTransferPicklist.unsubscribe();
      this.vehicles = res;
    });
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
      this.nameInput.nativeElement.select();
    }, 0);
  }

  ngAfterViewInit(): void {
    this.nameInput.nativeElement.focus();
    this.nameInput.nativeElement.select();
  }

  initFormIntern() {
    this.form = this.fb.group({
      name: ["", Validators.required],
      departureDate: ["", { validators: [Validators.required, dateTimeFormatValidator()], updateOn: "blur" }],
      arrivalDate: ["", { validators: [Validators.required, dateTimeFormatValidator()], updateOn: "blur" }],
      departure: ["", Validators.required],
      destination: ["", Validators.required],
      vehicle: ["", Validators.required],
      capacity: ["", Validators.required],
      twoWay: [true],
      country: [""],
    });
  }

  dateTimeMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/];
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  submitForm() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("transfer.fill_all_fields"));
      this.form.markAllAsTouched();
      return;
    }
    this.addTransfer();
  }

  handleEnter(event: KeyboardEvent, nextElementId: string): void {
    if (event.key === "Enter") {
      event.preventDefault();
      if (nextElementId) {
        const nextElement = document.getElementById(nextElementId);
        if (nextElement) {
          nextElement.focus();
        }
      } else {
        this.submitBtn.nativeElement.focus();
      }
    }
  }
  handleFocus(event: FocusEvent): void {
    const button = event.target as HTMLButtonElement;
    button.classList.add("focused");
  }
  fetchTransfer() {
    const { name, departureDate, arrivalDate, departure, vehicle, destination, capacity, twoWay } = this.form.getRawValue();
    const formattedDepartureDate = moment(departureDate, "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss");
    const formattedArrivalDate = moment(arrivalDate, "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss");
    const country = departure?.country || destination?.country || null;
    const data = {
      name,
      departureDate: formattedDepartureDate,
      arrivalDate: formattedArrivalDate,
      departureId: departure?.id,
      destinationId: destination?.id,
      vehicleOptionId: vehicle?.id,
      capacity,
      twoWay,
      country,
    };
    return data;
  }

  addTransfer() {
    const data = this.fetchTransfer();
    const createSub = this.transfersService.createTransfer(data).subscribe({
      next: () => {
        createSub.unsubscribe();
        showMessage(PopupType.Success, this.translate.instant("transfer.created_transfer_successfully"));
        this.router.navigate(["/dashboard/transfers"]);
      },
      error: () => {
        createSub.unsubscribe();
        showMessage(PopupType.Warning, this.translate.instant("transfer.error_while_creating_transfer"));
      },
    });
  }

  get twoWay() {
    return this.form?.get("twoWay")?.value;
  }
}
