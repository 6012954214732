import * as moment from "moment";
import { Subscription } from "rxjs";
import { Transfer } from "../models/transfer";
import { Sale } from "../../sales/models/sale";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Customer } from "../../customers/models/customer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TransfersService } from "../services/transfers.service.service";
import { SaleCreateComponent } from "../../sales/sale.create/sale.create.component";
import { PassangersListComponent } from "../../passangers/passangers.list/passangers.list.component";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";

@Component({
  selector: "app-transfer-plan",
  templateUrl: "./transfer-plan.component.html",
})
export class TransferPlanComponent implements OnInit, OnDestroy {
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("sale") sales!: SaleCreateComponent;
  activeTab = 1;
  passengers!: Customer[];
  sale!: Sale;
  transferId!: number | undefined;
  transfer!: Transfer;
  isForwardDirection!: boolean;
  private routeSubscriber!: Subscription;
  private customer!: Customer;

  constructor(private translate: TranslateService, private route: ActivatedRoute, private transferService: TransfersService, private router: Router) {}

  ngOnInit(): void {
    this.routeSubscriber = this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      this.transferId = id ? parseInt(id) : undefined;
      this.fetchTransfer(this.transferId);
    });
  }

  ngOnDestroy() {
    this.routeSubscriber.unsubscribe();
  }

  proceedToNextStage(nextStep: number | undefined = undefined) {
    if (this.activeTab === 1) {
      if (!this.passangerList.getPassangers().find((e: Customer) => e.mainCustomer)) return;
      this.customer = this.passangerList.getPassangers().find((e: Customer) => e.mainCustomer) as Customer;
      if (this.customer.name === undefined || this.customer.surname === undefined) {
        showMessage(PopupType.Danger, this.translate.instant("popup.main_customer_is_required"));
        return;
      }
    }
    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  fetchTransfer(id: number | undefined) {
    if (id) {
      this.transferService.getTransferData(id).subscribe((res: any) => {
        this.transfer = res;
      });
    }
  }
  toggleDirection() {
    this.isForwardDirection = !this.isForwardDirection;
  }

  addDirection(id: number) {
    if (id === 1) this.transferId = this.transfer.returnTransfer?.id;
    else this.transferId = this.transfer.id;
  }
  async submitTransfer() {
    try {
      if (this.sales.checkIfValid() && this.passangerList.checkValidity()) {
        this.passengers = this.passangerList.getPassangers();
        this.sale = this.sales.getSale();

        const addSub = this.transferService.addPassangerToTransfer(this.transferId, this.fetchTransferPlanData()).subscribe({
          next: () => {
            addSub.unsubscribe();
            showMessage(PopupType.Success, this.translate.instant("transfer.added_passangers_successfully"));
            this.router.navigate([`/dashboard/transfers/${this.transferId}`]);
          },
          error: () => {
            addSub.unsubscribe();
            showMessage(PopupType.Danger, this.translate.instant("transfer.error_while_adding_passangers"));
          },
        });
      }
    } catch (error) {
      showMessage(PopupType.Danger, this.translate.instant("transfer.error_while_adding_passangers"));
    }
  }

  fetchTransferPlanData() {
    return {
      transferId: this.transferId,
      passangers: this.passengers.map((p: any) => {
        return { customerId: p.id, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer };
      }),
      plan: { ...this.sale, dueDate: moment(this.sale.dueDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss"), customerId: this.customer.id },
      customerId: this.customer.id,
    };
  }
}
