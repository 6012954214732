<div class="my-2 space-y-2 font-semibold">
  <div class="rounded border border-[#d3d3d3] dark:border-[#1b2e4b]">
    <button type="button" class="flex w-full items-center p-4 text-white-dark dark:bg-[#1b2e4b]" [ngClass]="{ '!text-primary': accordion === orderNumber }" (click)="setVisibleAccordion()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0 text-primary ltr:mr-2 rtl:ml-2">
        <path
          opacity="0.5"
          d="M7.142 18.9706C5.18539 18.8995 3.99998 18.6568 3.17157 17.8284C2 16.6569 2 14.7712 2 11C2 7.22876 2 5.34315 3.17157 4.17157C4.34315 3 6.22876 3 10 3H14C17.7712 3 19.6569 3 20.8284 4.17157C22 5.34315 22 7.22876 22 11C22 14.7712 22 16.6569 20.8284 17.8284C20.0203 18.6366 18.8723 18.8873 17 18.965"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M9.94955 16.0503C10.8806 15.1192 11.3461 14.6537 11.9209 14.6234C11.9735 14.6206 12.0261 14.6206 12.0787 14.6234C12.6535 14.6537 13.119 15.1192 14.0501 16.0503C16.0759 18.0761 17.0888 19.089 16.8053 19.963C16.7809 20.0381 16.7506 20.1112 16.7147 20.1815C16.2973 21 14.8648 21 11.9998 21C9.13482 21 7.70233 21 7.28489 20.1815C7.249 20.1112 7.21873 20.0381 7.19436 19.963C6.91078 19.089 7.92371 18.0761 9.94955 16.0503Z"
          stroke="currentColor"
          stroke-width="1.5"
        />
      </svg>

      {{ title | translate }}
      <div class="ltr:ml-auto rtl:mr-auto" [ngClass]="{ 'rotate-180': accordion === 1 }">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4">
          <path d="M19 9L12 15L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </button>
    <div [@slideDownUp]="accordion !== orderNumber" class="accordion-content">
      <div class="space-y-2 border-t border-[#d3d3d3] p-4 text-[13px] text-white-dark dark:border-[#1b2e4b]">
        <div class="space-y-1">
          <div
            class="flex h-10 w-full items-center justify-between rounded-md p-2 font-medium hover:bg-white-dark/10 hover:text-primary dark:hover:bg-[#181F32] dark:hover:text-primary"
            *ngFor="let item of picklistType"
          >
            <div class="flex h-full w-full items-center py-2">
              <div class="flex w-full items-center">
                {{ item.title }}
              </div>
              <div class="flex items-center gap-1">
                <div>
                  <a type="button" [ngxTippy]="'office-branch.edit' | translate" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="openModalForEditing(item.id)">
                    <icon-edit />
                  </a>
                </div>
                <div>
                  <a type="button" [ngxTippy]="'office-branch.delete' | translate" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="deletePicklistOption(item.id)">
                    <icon-trash-lines />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <button type="button" class="btn btn-danger" (click)="openModal()">
              <icon-plus class="rotate-90 opacity-70 hover:opacity-100" />
              {{ "add_new_picklist_option" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal #modal>
  <ng-template #modalHeader>
    <div class="!font-medium">{{ modalTitle }}</div>
  </ng-template>
  <ng-template #modalBody>
    <form [formGroup]="form" class="text-sm">
      <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
        <div [ngClass]="form.controls['title'].touched ? (form.controls['title'].errors ? 'has-error' : 'has-success') : ''">
          <label for="name">{{ "settings.title" | translate }}</label>
          <input id="name" type="text" placeholder="{{ 'settings.title' | translate }}" class="form-input" formControlName="title" />
          <ng-container *ngIf="form.controls['title'].touched && form.controls['title'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "settings.please_provide_title" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
        <div [ngClass]="form.controls['description'].touched ? (form.controls['description'].errors ? 'has-error' : 'has-success') : ''">
          <label for="description">{{ "settings.description" | translate }}</label>
          <input id="description" type="text" placeholder="{{ 'settings.description' | translate }}" class="form-input" formControlName="description" />
          <ng-container *ngIf="form.controls['description'].touched && form.controls['description'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "settings.please_provide_description" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="mt-8 flex items-center justify-end">
        <button type="submit" class="btn btn-primary" (click)="addItem()">{{ modalButton }}</button>
      </div>
    </form>
  </ng-template>
</modal>
