import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TravelDestination } from "../models/travel-destination";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";

@Component({
  selector: "app-travel-destination-create",
  templateUrl: "./travel-destination.create.component.html",
  animations: [toggleAnimation],
})
export class TravelDestinationCreateComponent implements OnInit {
  @Input() defaultDestination: TravelDestination = {} as TravelDestination;
  @Input() id!: number;
  @Input() isMainDestination = true;
  @Output() submitDestination: EventEmitter<TravelDestination> = new EventEmitter<TravelDestination>();

  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;
  public form!: FormGroup;
  public isSubmitForm = false;

  constructor(
    public store: Store<AppState>,
    public translate: TranslateService,
    public fb: FormBuilder,
    public utilService: UtilService,
    public groupTravelService: GroupTravelsService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.initForm(this.defaultDestination);
    if (this.router.url.includes("/edit")) {
      this.fetchEntities();
    }
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.getTravel();
      }
    });
  }
  initForm(travel: TravelDestination | undefined) {
    if (travel) {
      const { id, destination, hotel, start, end, service, days, nights, roomDescription } = travel;
      const country = destination?.country || null;
      this.form = this.fb.group({
        id: [id || null],
        destination: [destination || "", { validators: [Validators.required], updateOn: "blur" }],
        hotel: [hotel || "", { validators: [Validators.required], updateOn: "blur" }],
        start: [start ? moment(start).format("DD.MM.YYYY") : "", { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        end: [end ? moment(end).format("DD.MM.YYYY") : "", { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        service: [service || "", { validators: [Validators.required], updateOn: "blur" }],
        days: [days || "", { validators: [Validators.required], updateOn: "blur" }],
        nights: [nights || "", { validators: [Validators.required], updateOn: "blur" }],
        roomDescription: [roomDescription || ""],
        isMainDestination: [true],
        country: [country],
      });
    } else {
      this.form = this.fb.group({
        id: [""],
        destination: ["", { validators: [Validators.required], updateOn: "blur" }],
        hotel: ["", { validators: [Validators.required], updateOn: "blur" }],
        start: ["", { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        end: ["", { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        service: ["", { validators: [Validators.required], updateOn: "blur" }],
        days: ["", { validators: [Validators.required], updateOn: "blur" }],
        nights: ["", { validators: [Validators.required], updateOn: "blur" }],
        roomDescription: [""],
        isMainDestination: [true],
      });
    }
  }
  setDaysNights() {
    if (!this.form.controls["start"].errors && !!this.form.controls["start"].value) {
      const start = moment(this.form.controls["start"].value, "DD.MM.YYYY", true);
      const newEndDate = start.add(this.form.controls["days"].value, "days");
      this.form.controls["end"].setValue(newEndDate.format("DD.MM.YYYY"));
    }
  }
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  fetchEntities() {
    if (this.id) {
      this.groupTravelService.getGroupTravelDestinations(this.id).subscribe((response) => {
        this.initForm(response);
        this.getTravel();
      });
    }
  }
  getTravel(): TravelDestination {
    const travelDestination = { ...this.form.getRawValue(), travelId: this.id, isMainDestination: true };
    return travelDestination;
  }
  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      return;
    }
    const rawValues = this.form.getRawValue();
    const editedDestination = {
      ...this.defaultDestination,
      ...rawValues,
      serviceId: rawValues.service?.id,
      isMainDestination: this.isMainDestination,
    };
    this.submitDestination.emit(editedDestination);
  }
  setStartAndEnd(event: any) {
    this.form.controls["start"].setValue(event.start);
    this.form.controls["end"].setValue(event.end);
  }
  save() {
    const country = this.form.controls["country"].value;
    const countryId = country?.id;
    if (countryId !== undefined) {
      this.utilService.selectCountry(countryId);
    }
  }
}
